import Vue from 'vue';
import { abPlugin } from '@webplatform/asbis-ui';

// eslint-disable-next-line func-names
export default function ({ $gsap, $ScrollTrigger }) {
  Vue.use(abPlugin, {
    preset: 'lorgar',
    components: {
      AbButton: {
        props: {
          effect: 'glow',
        },
      },
    },
    gsap: $gsap,
    ScrollTrigger: $ScrollTrigger,
  });
}
